import { Typography } from "@mui/material";
import clsx from "lib/clsx";
import Link from "next/link";
import { RefObject } from "react";
import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";

interface ICaptchaTextProps {
  className?: string;
}

const CaptchaText = ({ className }: ICaptchaTextProps) => {
  return (
    <Typography className={clsx("inline-block", className)} variant="caption">
      This site is protected by reCAPTCHA and the Google{" "}
      <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </Link>{" "}
      and{" "}
      <Link href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
        Terms of Service
      </Link>{" "}
      apply.
    </Typography>
  );
};

interface IProps {
  innerRef: RefObject<ReCAPTCHA>;
  className?: string;
  classes?: {
    captcha?: string;
    captchaText?: string;
  };
  size?: ReCAPTCHAProps["size"];
}

const Captcha = ({ innerRef, className, classes, size }: IProps) => {
  return (
    <div className={clsx("flex w-full flex-col gap-2", className)}>
      <ReCAPTCHA
        ref={innerRef}
        className={classes?.captcha}
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? ""}
        size={size ?? "invisible"}
      />
      <CaptchaText className={classes?.captchaText} />
    </div>
  );
};

export default Captcha;
