import { Box, InputAdornment, InputLabel, Typography, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Icon } from "components/shared";
import { add, isValid, max, min } from "date-fns";
import { useDeepCompareEffect } from "hooks";
import clsx from "lib/clsx";
import get from "lodash/get";
import { IDateField, IForm } from "models/form";
import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy } from "./hooks";

const DateField = ({
  name,
  label,
  title,
  helperText,
  required,
  disabled,
  color,
  size,
  variant,
  icon,
  styles,
  defaultByGroups,
  disableOpenPicker,
  minDate,
  maxDate,
  blockingRanges,
  dynamicMinDate,
  dynamicMaxDate,
  dynamicBlockingRanges,
  onOpen,
  onClose,
}: IDateField) => {
  const {
    control,
    formState: { errors },
    resetField,
  } = useFormContext<IForm<Date | null>>();

  const theme = useTheme();

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<string>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  useDeepCompareEffect(() => {
    const currentValueEqualsDefault = currentValue === defaultByValue;
    if (currentValueEqualsDefault || (!currentValue && !defaultByValue)) return;
    resetField(name, { defaultValue: currentValue || defaultByValue, keepDirty: true });
  }, [defaultByValue, currentValue, name, resetField]);
  //#endregion

  const error = get(errors, name);

  const combinedBlockingRanges = useMemo(() => {
    const date = new Date();
    if (!dynamicBlockingRanges?.length) return blockingRanges;
    return [
      ...(blockingRanges ?? []),
      ...dynamicBlockingRanges.map((d) => ({
        from: add(date, d.from).toDateString(),
        to: add(date, d.to).toDateString(),
      })),
    ];
  }, [blockingRanges, dynamicBlockingRanges]);

  const { minBlockDate, maxBlockDate } = useMemo(() => {
    const minDateValue = minDate ? new Date(minDate) : undefined;
    const dynamicMinDateValue = dynamicMinDate ? add(new Date(), dynamicMinDate) : undefined;
    const maxDateValue = maxDate ? new Date(maxDate) : undefined;
    const dynamicMaxDateValue = dynamicMaxDate ? add(new Date(), dynamicMaxDate) : undefined;

    const minBlockDate =
      !minDateValue || !dynamicMinDateValue
        ? minDateValue ?? dynamicMinDateValue
        : max([minDateValue!, dynamicMinDateValue!]);
    const maxBlockDate =
      !maxDateValue || !dynamicMaxDateValue
        ? maxDateValue ?? dynamicMaxDateValue
        : min([maxDateValue!, dynamicMaxDateValue!]);

    return { minBlockDate, maxBlockDate };
  }, [dynamicMaxDate, dynamicMinDate, maxDate, minDate]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { value, ...field } }) => {
        const parsedDate = typeof value === "string" ? new Date(value) : null;

        return (
          <Box className="space-y-4" sx={styles}>
            {label && (
              <InputLabel className="flex text-wrap text-light" htmlFor={name} required={required}>
                <Typography component="p" variant="p1">
                  {label}
                </Typography>
              </InputLabel>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                {...field}
                className={clsx(
                  "overflow-hidden text-light",
                  variant === "outlined" && "rounded-md",
                )}
                disableOpenPicker={disableOpenPicker}
                disabled={disabled}
                onOpen={onOpen}
                onClose={onClose}
                value={isValid(parsedDate) && parsedDate ? parsedDate : value || null}
                openTo="year"
                views={["year", "month", "day"]}
                minDate={minBlockDate}
                maxDate={maxBlockDate}
                shouldDisableDate={
                  !!combinedBlockingRanges?.length
                    ? (day) => {
                        const currentDate = new Date(day).getDate();
                        const currentMonth = new Date(day).getMonth();
                        const currentYear = new Date(day).getFullYear();

                        return combinedBlockingRanges.some((b) => {
                          const fromYear = new Date(b.from).getFullYear();
                          const toYear = new Date(b.to).getFullYear();
                          const fromMonth = new Date(b.from).getMonth();
                          const toMonth = new Date(b.to).getMonth();
                          const fromDate = new Date(b.from).getDate();
                          const toDate = new Date(b.to).getDate();

                          if (fromYear === toYear && fromMonth === toMonth) {
                            return (
                              currentYear === fromYear &&
                              currentMonth === fromMonth &&
                              currentDate > fromDate &&
                              currentDate < toDate
                            );
                          }

                          return (
                            (currentYear === fromYear &&
                              currentMonth === fromMonth &&
                              currentDate > fromDate) ||
                            (currentYear === toYear &&
                              currentMonth === toMonth &&
                              currentDate < toDate)
                          );
                        });
                      }
                    : undefined
                }
                shouldDisableMonth={
                  !!combinedBlockingRanges?.length
                    ? (month) => {
                        const currentMonth = new Date(month).getMonth();
                        const currentYear = new Date(month).getFullYear();

                        return combinedBlockingRanges.some((b) => {
                          const fromYear = new Date(b.from).getFullYear();
                          const toYear = new Date(b.to).getFullYear();

                          if (fromYear === toYear) {
                            return (
                              currentYear === fromYear &&
                              currentMonth > new Date(b.from).getMonth() &&
                              currentMonth < new Date(b.to).getMonth()
                            );
                          }

                          return (
                            (currentYear === fromYear &&
                              currentMonth > new Date(b.from).getMonth()) ||
                            (currentYear === toYear && currentMonth < new Date(b.to).getMonth())
                          );
                        });
                      }
                    : undefined
                }
                shouldDisableYear={
                  !!combinedBlockingRanges?.length
                    ? (year) => {
                        const current = new Date(year).getFullYear();
                        return combinedBlockingRanges.some(
                          (b) =>
                            current > new Date(b.from).getFullYear() &&
                            current < new Date(b.to).getFullYear(),
                        );
                      }
                    : undefined
                }
                slotProps={{
                  layout: {
                    sx: {
                      ".MuiPickersDay-today": {
                        borderColor: `var(--palette-${color}-main) !important`,
                      },
                      ".MuiDayCalendar-weekDayLabel": {
                        color: `var(--palette-${color}-dark)`,
                      },
                    },
                  },
                  popper: {
                    placement: "bottom-start",
                  },
                  desktopPaper: {
                    className: "bg-white/50 backdrop-blur-xl",
                    sx: {
                      ".Mui-disabled": {
                        color: `var(--palette-${color}-light) !important`,
                      },
                    },
                  },
                  mobilePaper: {
                    className: "bg-white/50 backdrop-blur-xl",
                  },
                  actionBar: {
                    sx: {
                      "& > button": {
                        color: `var(--palette-${color}-main)`,
                      },
                    },
                  },
                  textField: (props) => ({
                    ...props,
                    name,
                    required,
                    size,
                    variant,
                    color,
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message || helperText,
                    slotProps: {
                      formHelperText: {
                        className: "mx-0 text-inherit",
                      },
                    },
                    InputProps: {
                      ...props.InputProps,
                      componentsProps: !label ? { input: { "aria-label": title } } : undefined,
                      startAdornment: icon?.url && (
                        <InputAdornment position="start">
                          <Icon
                            className="flex h-auto w-6 items-center"
                            icon={icon}
                            sx={{
                              svg: {
                                display: "flex",
                                alignItems: "center",
                                width: "15px",
                                height: "auto",
                              },
                            }}
                          />
                        </InputAdornment>
                      ),
                    },
                    sx: {
                      "& .MuiInputBase-root": {
                        color: "inherit",
                        fontWeight: "inherit",
                        fontSize: theme.typography.p2.fontSize,
                        px: variant !== "standard" ? "10px" : undefined,
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        borderWidth: "2px",
                        borderTopWidth: "3.5px !important",
                        borderColor: disabled
                          ? `var(--palette-text-disabled)!important`
                          : `var(--palette-${color}-main)!important`,
                      },
                      "& input": {
                        py: size === "medium" ? "12.07px" : "7.32px",
                        px: variant !== "standard" ? "5px" : undefined,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        "&::placeholder": {
                          opacity: 1,
                          color: `var(--palette-${color}-main)`,
                        },
                      },
                      "& .Mui-focused .MuiInputAdornment-root svg": {
                        color: `var(--palette-${color}-main)`,
                      },
                    },
                  }),
                }}
              />
            </LocalizationProvider>
          </Box>
        );
      }}
    />
  );
};

export default DateField;
