import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses,
  NumberInputSlots,
} from "@mui/base/Unstable_NumberInput";
import { prepareForSlot } from "@mui/base/utils";
import { Add, ArrowDropUp, Remove } from "@mui/icons-material";
import { Box, FormHelperText, InputLabel, Theme, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Icon } from "components/shared";
import clsx from "lib/clsx";
import get from "lodash/get";
import merge from "lodash/merge";
import { IForm, INumberField } from "models/form";
import { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy } from "./hooks";
import { slots } from "./variants/NumberField/QuantityField";

export default function NumberInputAdornments({
  name,
  label,
  helperText,
  required,
  disabled,
  color,
  size,
  icon,
  styles,
  variant,
  placeholder,
  defaultByGroups,
  step,
  min,
  max,
  fieldVariant,
}: INumberField) {
  const {
    control,
    formState: { errors },
    resetField,
  } = useFormContext<IForm<number | null>>();

  const defaultSlots: NumberInputSlots = useMemo(
    () => ({
      root: prepareForSlot(InputRoot),
      input: prepareForSlot(
        // eslint-disable-next-line react/display-name
        forwardRef((props, ref: ForwardedRef<HTMLInputElement>) => (
          <InputElement ref={ref} {...props} fieldSize={size} />
        )),
      ),
      incrementButton: prepareForSlot((props) => <Button {...props} type="button" size={size} />),
      decrementButton: prepareForSlot((props) => <Button {...props} type="button" size={size} />),
    }),
    [size],
  );

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<number>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  useEffect(() => {
    const currentValueEqualsDefault = currentValue === defaultByValue;
    if (
      currentValueEqualsDefault ||
      typeof currentValue === "number" ||
      (!currentValue && !defaultByValue)
    )
      return;
    resetField(name, { defaultValue: currentValue ?? defaultByValue, keepDirty: true });
  }, [currentValue, defaultByValue, name, resetField]);
  //#endregion

  const error = get(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { value, ...field } }) => (
        <Box
          sx={merge({
            "& .base--focused .InputAdornment": {
              color: `var(--palette-${color}-main)`,
            },
            styles,
          })}
        >
          {label && (
            <InputLabel
              className="mb-4 flex text-wrap text-light"
              htmlFor={name}
              required={required}
            >
              <Typography component="p" variant="p1">
                {label}
              </Typography>
            </InputLabel>
          )}
          <BaseNumberInput
            {...field}
            onChange={(e, v) => {
              if (v === null) field.onChange(v);
              else if (!isNaN(v)) field.onChange(+v);
            }}
            required={required}
            disabled={disabled}
            step={step}
            min={min ?? undefined}
            max={max ?? undefined}
            error={!!error}
            slots={{ ...defaultSlots, ...(fieldVariant === "quantity" ? slots({ size }) : {}) }}
            slotProps={{
              root: {
                color,
              },
              input: {
                className: clsx("text-light font-sans", disabled && "text-disabled"),
                placeholder,
                color,
                inputMode: "numeric",
              },
              incrementButton: {
                children:
                  fieldVariant === "quantity" ? (
                    <Add className="text-lg" />
                  ) : (
                    <ArrowDropUp className="text-xl text-text-light" />
                  ),
                className: "increment",
              },
              decrementButton: {
                children:
                  fieldVariant === "quantity" ? (
                    <Remove className="text-lg" />
                  ) : (
                    <ArrowDropUp className="rotate-180 text-xl text-text-light" />
                  ),
              },
            }}
            startAdornment={
              icon?.url && (
                <InputAdornment>
                  <Icon
                    className="flex h-auto w-6 items-center"
                    icon={icon}
                    sx={{
                      svg: {
                        display: "flex",
                        alignItems: "center",
                        width: "24px",
                        height: "auto",
                      },
                    }}
                  />
                </InputAdornment>
              )
            }
          />
          {(error || helperText) && (
            <FormHelperText className="mx-0 text-light" error={!!error}>
              {error?.message || helperText}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
}

const InputAdornment = ({ children }: { children: ReactNode }) => (
  <Box
    className="InputAdornment m-1 inline-flex items-center justify-center"
    sx={{
      color: "var(--palette-action-active)",
      gridRow: "1/3",
    }}
  >
    {children}
  </Box>
);
const InputRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})(
  ({ theme, color }) => `
  border-radius: var(--shape-borderRadius);
  border: 2px solid var(--palette-${color}-main);
  display: grid;
  grid-template-columns: auto 1fr auto 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  padding: 1.5px 2.5px 1.5px 4px;
  align-items: center;

  &.${numberInputClasses.focused} {
    border-color: var(--palette-${color}-main);
  }

   &.${numberInputClasses.disabled} {
    border-color: var(--palette-text-disabled);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const InputElement = styled("input", { shouldForwardProp: (prop) => prop !== "fieldSize" })<{
  fieldSize: INumberField["size"];
}>(
  ({ theme, fieldSize }) => `
  grid-row: 1/3;
  border: none;
  padding: ${fieldSize === "small" ? "4.07px 9px" : "8.57px 9px"};
  outline: 0;
  color: inherit;
  background-color: transparent;
  font-weight: 400;
  font-size: ${(theme as Theme).typography.p2.fontSize};
  line-height: 1.4375em;
  font-feature-settings: "salt";
  width: 100%;
  grid-column: span 2 / span 2;
`,
);

const Button = styled("button", { shouldForwardProp: (prop) => prop !== "size" })<{
  size: INumberField["size"];
}>(
  ({ theme, size }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 19px;
  height: ${size === "small" ? "14px" : "20px"};
  background: #fff;
  color: var(--palette-text-primary);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: var(--palette-FilledInput-hoverBg);
    border-color: var(--palette-FilledInput-hoverBg);
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 4/5;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    border-color: rgb(var(--palette-common-onBackgroundChannel) / 0.23);
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 4/5;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    border-color: rgb(var(--palette-common-onBackgroundChannel) / 0.23);
  }
`,
);
