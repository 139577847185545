import { Grouping, IForm, ISelectOption } from "models/form";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

const useFilteredSelectOptions = (name: string, options: ISelectOption[] | null) => {
  const { watch, resetField } = useFormContext<IForm<string | string[]>>();

  const fieldValues = watch(name);

  const keys = useMemo(
    () =>
      Array.from(
        new Set(
          options?.flatMap(({ groupings }) => {
            return !groupings
              ? []
              : ("or" in groupings && groupings.or && groupings.or.length > 0
                  ? groupings.or
                  : "and" in groupings && groupings.and && groupings.and.length > 0
                    ? groupings.and
                    : []
                )?.flatMap((grouping) => grouping.key) || [];
          }),
        ),
      ),
    [options],
  );

  const watchedValues = Object.fromEntries(keys.map((key) => [key, watch(key)]));

  const filteredOptions = useMemo(
    () =>
      options?.filter((option) => {
        const { groupings } = option;
        if (!groupings) return option;

        const checkGrouping = ({ key, value }: Grouping): boolean => {
          if (value.or) {
            const fieldValue = watchedValues[key];
            const valueArray = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

            if (
              value.or.some((item) =>
                (typeof item === "string" ? [item] : item.and).every((v) => valueArray.includes(v)),
              )
            ) {
              return true;
            }
          }

          return false;
        };

        if ("and" in groupings && groupings.and && groupings.and.length > 0)
          return groupings.and.every(checkGrouping);
        if ("or" in groupings && groupings.or && groupings.or.length > 0)
          return groupings.or.some(checkGrouping);
        return false;
      }),
    [options, watchedValues],
  );

  useEffect(() => {
    if (
      !!keys.length &&
      !!fieldValues?.length &&
      !(Array.isArray(fieldValues) ? fieldValues : [fieldValues]).every((v) =>
        filteredOptions?.find((o) => o.name === v),
      )
    ) {
      resetField(name);
    }
  }, [fieldValues, filteredOptions, keys.length, name, resetField]);

  if (!keys.length) return options;

  return filteredOptions;
};

export default useFilteredSelectOptions;
