import { useDeepCompareEffect } from "hooks";
import { FilterBy, IFormField } from "models/form";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

const useDefaultBy = <T,>(defaultByGroups: IFormField["defaultByGroups"]) => {
  const { watch } = useFormContext();

  const keys = useMemo(
    () =>
      Array.from(
        new Set(
          defaultByGroups?.flatMap((group) => {
            return !group
              ? []
              : ("or" in group && group.or.length > 0
                  ? group.or
                  : "and" in group && group.and.length > 0
                    ? group.and
                    : []
                )?.flatMap((item) => item.key) || [];
          }),
        ),
      ),
    [defaultByGroups],
  );

  const watching = Object.fromEntries(keys.map((key) => [key, watch(key)]));
  const [watchedValues, setWatchedValues] = useState<typeof watching>({});
  useDeepCompareEffect(() => setWatchedValues(watching), [watching]);

  const [defaultByValue, setDefaultByValue] = useState<T>();

  useEffect(() => {
    //* Reset defaultByValue so that it won't apply again when some other watching value changes
    setDefaultByValue(undefined);
  }, [watching]);

  useEffect(() => {
    const checked = defaultByGroups?.map((group) => {
      if (!group) return null;

      const checkGrouping = ({ key, value }: FilterBy): boolean => {
        if (value.or) {
          const fieldValue = watchedValues[key];
          const valueArray = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

          if (
            value.or.some((item) =>
              (typeof item === "string" ? [item] : item.and).every((v) => valueArray.includes(v)),
            )
          ) {
            return true;
          }
        }

        return false;
      };

      if ("and" in group && group.and.length > 0)
        return group.and.every(checkGrouping) ? (group.defaultValue as T) : null;
      if ("or" in group && group.or.length > 0)
        return group.or.some(checkGrouping) ? (group.defaultValue as T) : null;
      return null;
    });

    setDefaultByValue(checked?.find((c): c is T => !!c));
  }, [defaultByGroups, watchedValues]);

  return defaultByValue;
};

export default useDefaultBy;
