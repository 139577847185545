import { Grid } from "@mui/material";
import {
  FormField,
  IAutoComplete,
  ICheckboxGroup,
  IDateField,
  INumberField,
  IPhoneNumberField,
  IRadioGroup,
  ITextArea,
  ITextField,
} from "models/form";
import { useEffect } from "react";
import { AutoComplete, CheckboxGroup, DateField, NumberField, RadioGroup, TextField } from ".";
import { useFormFields } from "../FormProvider";
import PhoneNumberField from "./PhoneNumberField";
import { useVisibleBy } from "./hooks";

const Field = ({ visibleByGroups, visible, ...field }: FormField) => {
  //#region Set visibleBy
  const { fields, setFields } = useFormFields();
  const visibleByValue = useVisibleBy(visibleByGroups);

  useEffect(() => {
    const valueExists = fields.some((f) => f.name === field.name);

    if (valueExists && !visibleByValue) {
      setFields((prev) => prev.filter((f) => f.name !== field.name));
    } else if (!valueExists && visibleByValue) {
      setFields((prev) => [...prev, field]);
    }
  }, [field, fields, setFields, visibleByValue]);
  //#endregion

  const isTextField = field.type === "ComponentFormText" || field.type === "ComponentFormTextArea";

  if (!visibleByValue || visible === false) {
    return null;
  }

  return (
    <Grid
      className="w-full"
      item
      xs={
        field.width === "full"
          ? 12
          : field.width === "two_thirds"
            ? 8
            : field.width === "half"
              ? 6
              : field.width === "one_third" && 4
      }
    >
      {isTextField ? (
        <TextField {...(field as ITextField & ITextArea)} />
      ) : field.type === "ComponentFormNumber" ? (
        <NumberField {...(field as INumberField)} />
      ) : field.type === "ComponentFormAutoComplete" ? (
        <AutoComplete {...(field as IAutoComplete)} />
      ) : field.type === "ComponentFormDate" ? (
        <DateField {...(field as IDateField)} />
      ) : field.type === "ComponentFormCheckboxGroup" ? (
        <CheckboxGroup {...(field as ICheckboxGroup)} />
      ) : field.type === "ComponentFormRadioGroup" ? (
        <RadioGroup {...(field as IRadioGroup)} />
      ) : (
        field.type === "ComponentFormPhoneNumber" && (
          <PhoneNumberField {...(field as IPhoneNumberField)} />
        )
      )}
    </Grid>
  );
};

export default Field;
