import { ExpandMore } from "@mui/icons-material";
import { Checkbox, Collapse, FormControlLabel, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button, Icon, InnerHTML } from "components/shared";
import clsx from "lib/clsx";
import without from "lodash/without";
import { useMemo } from "react";
import { IItemProps } from "../../CheckboxGroup";

const InverseCheckboxItem = ({
  field: { value, ...field },
  option: { name, label, icon },
  disabled,
  size,
  color,
  checkboxPosition,
  expanded,
  setExpanded,
}: IItemProps) => {
  const theme = useTheme();
  const isExpanded = useMemo(() => expanded.some((e) => e.includes(name)), [expanded, name]);
  const isSelected = value.includes(name);

  return (
    <Button
      key={name}
      className="block h-full text-wrap p-[2.4rem] text-start font-normal normal-case text-light !shadow-inner drop-shadow-md"
      component="div"
      disabled={disabled}
      disableRipple={isSelected}
      size={size}
      color={color}
      disableElevation
      sx={{
        backgroundColor: `var(--palette-background-default)`,
      }}
      onClick={(e) => {
        e.preventDefault();
        const currentValues = value;
        const currentChecked = currentValues.includes(name);
        const newValues = !currentChecked
          ? [...currentValues, name]
          : currentValues.filter((v) => v !== name);
        field.onChange([...newValues]);
      }}
    >
      <FormControlLabel
        {...field}
        className={clsx(
          "m-0 w-full text-wrap text-start",
          size === "medium" ? "min-h-[3rem]" : "min-h-[2.05rem]",
          checkboxPosition === "top" && label?.secondary && "flex items-start",
        )}
        classes={{
          label: "w-full",
        }}
        labelPlacement="start"
        control={
          <Checkbox
            className="absolute flex p-0"
            disableRipple
            name={name}
            size={size}
            sx={{
              color: disabled
                ? `var(--palette-text-disabled) !important`
                : `var(--palette-${color}-main)`,

              svg: {
                transform: "scale(1.33)",
              },
            }}
          />
        }
        label={
          !label ? undefined : (
            <>
              <div className="flex items-center justify-between">
                <div className="mx-1.5 flex flex-wrap">
                  <div className="mb-[1.6rem] mr-12 flex w-full items-baseline">
                    {icon && (
                      <Icon
                        className="mr-4 flex h-auto w-6 items-center object-contain"
                        icon={icon}
                        sx={{
                          svg: {
                            display: "flex",
                            alignItems: "center",
                            width: "15px",
                            height: "auto",
                          },
                        }}
                      />
                    )}
                    <InnerHTML className="leading-tight duration-150" variant="h5">
                      {label.primary}
                    </InnerHTML>
                  </div>
                  <InnerHTML className="space-y-[8px] tracking-tight text-text-light" variant="p3">
                    {label.secondary}
                  </InnerHTML>
                </div>
                {label.content && (
                  <IconButton
                    aria-expanded={isExpanded}
                    aria-label="show more"
                    className={clsx(
                      isExpanded && "-rotate-180",
                      "transition-transform duration-300",
                    )}
                    size="small"
                    onClick={() => {
                      setExpanded((prev) =>
                        prev.includes(name) ? without(prev, name) : [...prev, name],
                      );
                    }}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </div>
              {label.content && (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <InnerHTML
                    className="m-1.5 text-light"
                    variant="caption"
                    sx={{ font: theme.typography.caption }}
                  >
                    {label.content}
                  </InnerHTML>
                </Collapse>
              )}
            </>
          )
        }
        disabled={disabled}
        checked={value.includes(name)}
      />
    </Button>
  );
};

export default InverseCheckboxItem;
