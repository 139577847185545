import { ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button, Icon, InnerHTML } from "components/shared";
import { useDeepCompareEffect } from "hooks";
import clsx from "lib/clsx";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import without from "lodash/without";
import { ICheckboxGroup, IForm, ISelectOption } from "models/form";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Controller, ControllerRenderProps, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy, useFilteredSelectOptions } from "./hooks";
import InverseCheckboxItem from "./variants/CheckboxGroup/InverseCheckboxItem";

export interface IItemProps
  extends Pick<ICheckboxGroup, "disabled" | "size" | "color" | "checkboxPosition" | "variant"> {
  field: ControllerRenderProps<IForm<string[]>, string>;
  option: ISelectOption;
  expanded: string[];
  setExpanded: Dispatch<SetStateAction<string[]>>;
}

const Item = ({
  field: { value, ...field },
  option: { name, label, icon },
  disabled,
  size,
  color,
  checkboxPosition,
  variant,
  expanded,
  setExpanded,
}: IItemProps) => {
  const theme = useTheme();
  const isExpanded = useMemo(() => expanded.some((e) => e.includes(name)), [expanded, name]);
  const isSelected = value.includes(name);

  return (
    <Button
      key={name}
      className="block h-full text-wrap p-0 text-start font-normal normal-case text-light"
      component="div"
      disabled={disabled}
      disableRipple={variant === "text" || isSelected}
      variant={
        variant === "text" || variant === "outlined" || variant === "contained"
          ? variant
          : undefined
      }
      size={size}
      color={color}
      disableElevation
      classes={{ root: "!p-0" }}
      sx={
        variant === "contained"
          ? {
              color: `var(--palette-${color}-main)`,
              backgroundColor: isSelected
                ? `rgb(var(--palette-${color}-mainChannel) / 0.35)`
                : `rgb(var(--palette-${color}-mainChannel) / 0.2)`,
              ":hover": {
                backgroundColor: isSelected
                  ? `rgb(var(--palette-${color}-mainChannel) / 0.4)`
                  : `rgb(var(--palette-${color}-mainChannel) / 0.3)`,
              },
            }
          : variant === "outlined"
            ? {
                borderRadius: "var(--shape-borderRadius)",
                borderWidth: "2px !important",
                borderColor: `var(--palette-${color}-main)`,
              }
            : variant === "text"
              ? { width: "fit-content", backgroundColor: "transparent !important" }
              : {}
      }
    >
      <FormControlLabel
        {...field}
        className={clsx(
          "m-0 w-full text-wrap text-start",
          size === "medium"
            ? variant === "outlined"
              ? "min-h-[2.6rem]"
              : "min-h-[3rem]"
            : "min-h-[2.05rem]",
          variant !== "text" ? "px-2 py-[1.1rem]" : "py-0.5",
          checkboxPosition === "top" &&
            (variant === "text" || label?.secondary) &&
            "flex items-start",
        )}
        classes={{
          label: "w-full",
        }}
        control={
          <Checkbox
            className={clsx("mx-3.5 px-0 py-0", variant === "text" && "-mt-0.5 ml-0")}
            disableRipple
            name={name}
            size={size}
            sx={{
              color: disabled
                ? `var(--palette-text-disabled) !important`
                : `var(--palette-${color}-main)`,

              svg: {
                transform: "scale(1.33)",
              },
            }}
          />
        }
        label={
          !label ? undefined : (
            <>
              <div className="flex items-center justify-between">
                <div className="mx-1.5 flex flex-wrap">
                  <div className="flex w-full items-baseline">
                    {icon && (
                      <Icon
                        className="mr-4 flex h-auto w-6 items-center object-contain"
                        icon={icon}
                        sx={{
                          svg: {
                            display: "flex",
                            alignItems: "center",
                            width: "15px",
                            height: "auto",
                          },
                        }}
                      />
                    )}
                    <InnerHTML
                      className="leading-tight duration-150"
                      variant={size === "medium" ? "p2" : "p3"}
                    >
                      {label.primary}
                    </InnerHTML>
                  </div>
                  <InnerHTML className="mt-3 tracking-tight text-text-light/70" variant="p3">
                    {label.secondary}
                  </InnerHTML>
                </div>
                {label.content && (
                  <IconButton
                    aria-expanded={isExpanded}
                    aria-label="show more"
                    className={clsx(
                      isExpanded && "-rotate-180",
                      "transition-transform duration-300",
                    )}
                    size="small"
                    onClick={() => {
                      setExpanded((prev) =>
                        prev.includes(name) ? without(prev, name) : [...prev, name],
                      );
                    }}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </div>
              {label.content && (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <InnerHTML
                    className="m-1.5 text-light"
                    variant="caption"
                    sx={{ font: theme.typography.caption }}
                  >
                    {label.content}
                  </InnerHTML>
                </Collapse>
              )}
            </>
          )
        }
        disabled={disabled}
        checked={value.includes(name)}
        onChange={(_, checked) => {
          const currentValues = value;
          const newValues = checked
            ? [...currentValues, name]
            : currentValues.filter((v) => v !== name);
          field.onChange([...newValues]);
        }}
      />
    </Button>
  );
};

const CheckboxGroup = ({
  name,
  label,
  title,
  helperText,
  required,
  disabled,
  color,
  size,
  icon,
  styles,
  defaultByGroups,
  itemWidth,
  checkboxPosition,
  variant,
  checkboxGroupOptions,
}: ICheckboxGroup) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<IForm<string[]>>();
  const filteredOptions = useFilteredSelectOptions(name, checkboxGroupOptions);
  const grouped =
    filteredOptions?.reduce(
      (result: { [key: string]: ISelectOption[] }, current: ISelectOption) => {
        const label = current.groupings?.label ?? "";
        result[label] = result[label] || [];
        result[label].push(current);
        return result;
      },
      {},
    ) ?? [];
  const options = Object.entries(grouped).map(([title, items]) => ({ title, items }));

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<string | string[]>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  const defaultByValueTransformed = useMemo(() => {
    const currentFilteredValues = filteredOptions
      ?.filter((o) => currentValue?.includes(o.name))
      .map((o) => o.name);

    return defaultByValue
      ? Array.isArray(defaultByValue)
        ? defaultByValue
        : !currentValue.includes(defaultByValue)
          ? [...(currentValue as string[]), defaultByValue]
          : currentFilteredValues
      : filteredOptions?.find((o) => currentValue?.includes(o.name))
        ? currentFilteredValues
        : [];
  }, [currentValue, defaultByValue, filteredOptions]);

  useEffect(() => {
    const currentValueEqualsDefault = isEqual(
      [...(currentValue ?? [])].sort(), //* Spread because sort() mutate
      defaultByValueTransformed!.sort(),
    );

    if (currentValueEqualsDefault || (!currentValue && !defaultByValueTransformed)) return;
    resetField(name, { defaultValue: defaultByValueTransformed, keepDirty: true });
  }, [currentValue, defaultByValueTransformed, name, resetField]);
  //#endregion

  const [expanded, setExpanded] = useState<string[]>([]);
  const [expandAll, setExpandAll] = useState(false);
  const optionNames = useMemo(() => filteredOptions?.map((o) => o.name), [filteredOptions]) ?? [];
  const error = get(errors, name);

  useDeepCompareEffect(() => {
    if (expandAll) {
      setExpanded(optionNames);
    } else if (!expandAll) {
      setExpanded([]);
    }
  }, [expandAll, optionNames]);

  useEffect(() => {
    if (expanded.length === optionNames.length) {
      setExpandAll(true);
    } else if (expanded.length === 0) {
      setExpandAll(false);
    }
  }, [expanded, optionNames.length]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <FormControl
          className="w-full space-y-4"
          component="fieldset"
          required={required}
          sx={styles}
        >
          {(label || title || icon) && (
            <div className={clsx("flex items-center", title && "mb-5")}>
              {icon && (
                <Icon
                  className="mr-4 flex h-auto w-6 items-center object-contain"
                  icon={icon}
                  sx={{
                    svg: {
                      display: "flex",
                      alignItems: "center",
                      width: "15px",
                      height: "auto",
                    },
                  }}
                />
              )}
              {(label || title) && (
                <FormLabel className="flex text-light" component="legend" error={!!error}>
                  <Typography
                    component="p"
                    variant={title ? (size === "medium" ? "h4" : "h5") : "p1"}
                  >
                    {label || title}
                  </Typography>
                </FormLabel>
              )}
            </div>
          )}
          <FormGroup aria-label={label} className="space-y-16">
            {options?.map(({ title, items }, i) => (
              <div key={title ?? i} className="space-y-[2.4rem]">
                {title && (
                  <Typography className="mx-auto w-fit text-center" variant="h3">
                    {title}
                  </Typography>
                )}
                <div className="grid grid-cols-12 gap-[16px]">
                  {items.map((o) => {
                    const props = {
                      field,
                      option: o,
                      disabled,
                      size,
                      color,
                      checkboxPosition,
                      variant,
                      expanded,
                      setExpanded,
                    };

                    return (
                      <div
                        key={o.name}
                        className={clsx(
                          "col-span-full",
                          itemWidth === "full"
                            ? "sm:col-span-full"
                            : itemWidth === "two_thirds"
                              ? "sm:col-span-8"
                              : itemWidth === "half"
                                ? "sm:col-span-6"
                                : itemWidth === "one_third" && "sm:col-span-4",
                        )}
                      >
                        {variant === "inverse" ? (
                          <InverseCheckboxItem {...props} />
                        ) : (
                          <Item {...props} />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
            {(error || helperText) && (
              <FormHelperText className="mx-0 !mt-2 text-light" error={!!error}>
                {error?.message || helperText}
              </FormHelperText>
            )}
          </FormGroup>
        </FormControl>
      )}
    />
  );
};

export default CheckboxGroup;
