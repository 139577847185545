import { FilterBy, IFormField } from "models/form";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

const useVisibleBy = (visibleByGroups: IFormField["visibleByGroups"]) => {
  const { watch } = useFormContext();

  const keys = useMemo(
    () =>
      Array.from(
        new Set(
          visibleByGroups?.flatMap((group) => {
            return !group
              ? []
              : ("or" in group && group.or.length > 0
                  ? group.or
                  : "and" in group && group.and.length > 0
                    ? group.and
                    : []
                )?.flatMap((item) => item.key) || [];
          }),
        ),
      ),
    [visibleByGroups],
  );

  const watchedValues = Object.fromEntries(keys.map((key) => [key, watch(key)]));

  const visibleByChecked = useMemo(
    () =>
      visibleByGroups?.map((group) => {
        if (!group) return false;

        const checkGrouping = ({ key, value }: FilterBy): boolean => {
          if (value.or) {
            const fieldValue = watchedValues[key];
            const valueArray = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

            if (
              value.or.some((item) =>
                (typeof item === "string" ? [item] : item.and).every((v) => valueArray.includes(v)),
              )
            ) {
              return true;
            }
          }

          return false;
        };

        if ("and" in group && group.and.length > 0) return group.and.every(checkGrouping);
        if ("or" in group && group.or.length > 0) return group.or.some(checkGrouping);
        return false;
      }),
    [visibleByGroups, watchedValues],
  );

  return !visibleByChecked || visibleByChecked.length === 0 || visibleByChecked.some(Boolean);
};

export default useVisibleBy;
