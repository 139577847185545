import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { lighten, useTheme } from "@mui/material/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Icon } from "components/shared";
import clsx from "lib/clsx";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { IAutoComplete, IForm, ISelectOption, SelectRenderOption } from "models/form";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy, useFilteredSelectOptions } from "./hooks";

const getFirstLetter = (label: string, grouping: IAutoComplete["grouping"]) => {
  const firstLetter = label.toUpperCase()?.[0];

  return grouping?.symbols && /[^A-Za-z0-9]/.test(firstLetter)
    ? "*"
    : grouping?.digits && /[0-9]/.test(firstLetter)
      ? "0-9"
      : firstLetter;
};

const OptionsRender = ({ label, icon }: Pick<ISelectOption, "label" | "icon">) => {
  return (
    <span className="relative flex items-center justify-start gap-2 md:justify-center">
      {icon && (
        <Icon
          className="flex h-auto w-6 items-center object-contain"
          icon={icon}
          sx={{ svg: { display: "flex", alignItems: "center", width: "15px", height: "auto" } }}
        />
      )}
      {label?.primary && (
        <Typography className="line-clamp-2 truncate" fontSize="inherit" fontWeight="inherit">
          {label.primary}
        </Typography>
      )}
    </span>
  );
};

const AutoComplete = ({
  name,
  label,
  helperText,
  required,
  disabled,
  color,
  size,
  icon,
  styles,
  variant,
  inputVariant,
  placeholder,
  defaultByGroups,
  multiple,
  selectVariant,
  chipVariant,
  chipsPlacement,
  disableClearable,
  grouping,
  autoCompleteOptions,
  title,
}: IAutoComplete) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<IForm<string | string[]>>();

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const filteredOptions = useFilteredSelectOptions(name, autoCompleteOptions);

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<string | string[]>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  const defaultByValueTransformed = useMemo(() => {
    const currentFilteredValues = filteredOptions
      ?.filter((o) => (multiple ? currentValue.includes(o.name) : o.name === currentValue))
      .map((o) => o.name);

    return defaultByValue
      ? multiple
        ? Array.isArray(defaultByValue)
          ? defaultByValue
          : !currentValue.includes(defaultByValue)
            ? [...(currentValue as string[]), defaultByValue]
            : currentFilteredValues
        : defaultByValue
      : filteredOptions?.find((o) =>
            multiple ? currentValue.includes(o.name) : o.name === currentValue,
          )
        ? multiple
          ? currentFilteredValues
          : currentFilteredValues?.[0]
        : multiple
          ? []
          : "";
  }, [currentValue, defaultByValue, filteredOptions, multiple]);

  useEffect(() => {
    const currentValueEqualsDefault = multiple
      ? isEqual((currentValue as string[]).sort(), (defaultByValueTransformed as string[]).sort())
      : currentValue === defaultByValueTransformed;

    if (currentValueEqualsDefault || (!currentValue && !defaultByValueTransformed)) return;
    resetField(name, { defaultValue: defaultByValueTransformed, keepDirty: true });
  }, [defaultByValueTransformed, currentValue, multiple, name, resetField]);
  //#endregion

  const options: SelectRenderOption[] = useMemo(() => {
    return (
      filteredOptions?.map((o) => ({
        key: o.name,
        label: o.label,
        value: <OptionsRender {...o} />,
        icon: o.icon,
        firstLetter: getFirstLetter(o.label?.primary ?? "*", grouping),
        groupingLabel: o.groupings?.label || "",
      })) ?? []
    );
  }, [filteredOptions, grouping]);
  const error = get(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={multiple ? [] : ""}
      render={({ field: { value, onChange, ...field } }) => {
        const selectedOptions = options.filter((o) =>
          typeof value === "string" ? value === o.key : value.includes(o.key),
        );

        const allSelected = selectedOptions.length === options.length;
        const limitTags = 1;

        return (
          <Box className="space-y-4" sx={styles}>
            {label && (
              <InputLabel className="flex text-wrap text-light" htmlFor={name} required={required}>
                <Typography component="p" variant="p1">
                  {label}
                </Typography>
              </InputLabel>
            )}
            <Autocomplete
              {...field}
              multiple
              limitTags={limitTags}
              disableClearable={disableClearable}
              disableCloseOnSelect={multiple}
              disabled={disabled}
              open={open}
              onKeyDown={(e) => {
                if (inputVariant === "select") e.preventDefault();
              }}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, val) => option.key === val.key}
              options={
                grouping
                  ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
                  : options.sort((a, b) => -b.groupingLabel.localeCompare(a.groupingLabel))
              }
              filterOptions={(options, params) => {
                const filtered = createFilterOptions<SelectRenderOption>()(options, params);

                if (!!filtered.length && multiple) {
                  return [
                    {
                      key: "select-all",
                      value: OptionsRender({
                        label: { primary: "Select all" + (title ? ` (${title})` : "") },
                      }),
                      groupingLabel: "",
                      firstLetter: "",
                    },
                    ...filtered,
                  ];
                }

                return filtered;
              }}
              getOptionLabel={(option) =>
                autoCompleteOptions?.find((o) => o.name === option.key)?.label?.primary ??
                option.key
              }
              groupBy={(option) => (grouping ? option.firstLetter : option.groupingLabel)}
              value={selectedOptions}
              onChange={(_event, val) => {
                let changedValue: string | string[] = [];

                if (multiple) {
                  if (val.some((v) => v.key === "select-all")) {
                    if (!allSelected) {
                      changedValue = options.map((o) => o.key);
                    }
                  } else {
                    changedValue = val.map((v) => v.key);
                  }
                } else {
                  changedValue = val.find((v) => !selectedOptions.includes(v))?.key ?? "";
                }

                onChange(changedValue);
              }}
              classes={{
                paper: "bg-white border-2 border-solid my-2 shadow-lg",
                listbox: "py-0",
                option: size === "small" ? "!py-1.5" : "!py-2",
                noOptions: clsx("text-light", size === "small" ? "" : "py-4"),
                popupIndicator: "mr-0.5",
              }}
              slotProps={{
                paper: {
                  sx: {
                    borderColor: `var(--palette-${color}-main)`,

                    "& > ul > .mui-0:first-of-type": {
                      position: "sticky",
                      top: 0,
                      zIndex: 20,
                    },
                  },
                },
                popupIndicator: {
                  sx: {
                    color: `var(--palette-${color}-main)`,
                  },
                },
                clearIndicator: {
                  sx: {
                    color: `var(--palette-${color}-main)`,
                  },
                },
              }}
              renderGroup={(params) => (
                <Box key={params.key}>
                  {params.group && (
                    <Box
                      className="sticky z-10 px-6 py-1 font-semibold"
                      sx={{
                        color: `var(--palette-${color}-dark)`,
                        backgroundColor: lighten(theme.palette[color].main, 0.7),
                        top: size === "small" ? "37.5px" : "47px",
                      }}
                    >
                      {params.group}
                    </Box>
                  )}
                  {params.children}
                </Box>
              )}
              renderOption={(props, option, { selected, inputValue }) => {
                const matches = match(option.label?.primary ?? "", inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.label?.primary ?? "", matches);

                return (
                  <Box
                    {...props}
                    className={clsx(
                      props.className,
                      option.key === "select-all" &&
                        "sticky top-0 z-10 bg-white/50 shadow backdrop-blur",
                    )}
                    component="li"
                    sx={{
                      backgroundColor: selected
                        ? "var(--palette-background-paper) !important"
                        : undefined,
                      "&.Mui-focused": {
                        backgroundColor: !selected
                          ? "var(--palette-background-default) !important"
                          : undefined,
                      },
                    }}
                  >
                    {selectVariant === "checkbox" && (
                      <Checkbox
                        className="mr-3"
                        size={size}
                        checked={option.key === "select-all" ? allSelected : selected}
                        indeterminate={
                          option.key === "select-all" && !!selectedOptions.length && !allSelected
                        }
                        classes={{
                          root: size === "small" ? "w-12 h-12" : "w-[37px] h-[37px]",
                        }}
                        sx={{
                          color: `var(--palette-${color}-main)`,
                        }}
                      />
                    )}
                    {option.icon && (
                      <Icon
                        className="mr-4 flex h-auto w-6 items-center object-contain"
                        icon={icon}
                        sx={{
                          svg: {
                            display: "flex",
                            alignItems: "center",
                            width: "15px",
                            height: "auto",
                          },
                        }}
                      />
                    )}
                    {option.key === "select-all" && option.value}
                    <div>
                      {parts.map((part, index) => (
                        <span key={index} className={clsx(part.highlight && "font-bold", "")}>
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </Box>
                );
              }}
              renderTags={(value, getTagProps) =>
                chipsPlacement === "inside" &&
                value.map(({ key, value: label }, index) => (
                  <Chip
                    size="small"
                    color="primary"
                    variant={chipVariant}
                    label={label}
                    sx={{
                      m: "0 !important",
                      maxHeight: "23px",

                      "&.MuiChip-root": {
                        maxWidth: "74%",
                        ...(chipVariant === "filled"
                          ? {
                              color: "var(--palette-text-secondary) !important",
                              backgroundColor: "var(--palette-primary-dark)",
                            }
                          : { color: "var(--palette-text-primary) !important" }),
                      },

                      "& .MuiChip-label p": {
                        fontSize: theme.typography.p3.fontSize,
                        display: "block",
                      },
                    }}
                    {...getTagProps({ index })}
                    {...(disableClearable ? { onDelete: undefined } : {})}
                    key={key + index}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={clsx(
                    "overflow-hidden text-light",
                    variant === "outlined" && "rounded-md",
                  )}
                  hiddenLabel
                  name={name}
                  placeholder={
                    selectedOptions.length === 1 || placeholder === null
                      ? undefined
                      : (!value?.length && placeholder
                          ? placeholder
                          : !options?.length
                            ? `Select ${label}`
                            : (allSelected
                                ? "All"
                                : chipsPlacement === "outside"
                                  ? selectedOptions.length || ""
                                  : "") +
                              ((chipsPlacement === "outside" && !!selectedOptions.length) ||
                              allSelected
                                ? " "
                                : "") +
                              (title && !!selectedOptions.length ? `${title} ` : "") +
                              (!!selectedOptions.length ? "selected" : "Select")) +
                        (!required ? " (optional)" : "")
                  }
                  required={required && !value.length}
                  disabled={disabled}
                  type="text"
                  size={size}
                  variant={variant}
                  color={color}
                  focused={variant === "outlined" || undefined}
                  fullWidth
                  error={!!error}
                  helperText={error?.message || helperText}
                  slotProps={{
                    formHelperText: {
                      className: "mx-0 text-inherit",
                    },
                    input: {
                      ...params.InputProps,
                      componentsProps: !label ? { input: { "aria-label": title } } : undefined,
                      readOnly: true,
                      classes:
                        inputVariant === "select"
                          ? {
                              root: "cursor-pointer",
                              input: "cursor-pointer caret-transparent !min-w-0",
                            }
                          : undefined,
                      startAdornment: (
                        <>
                          {icon?.url && (
                            <InputAdornment position="start">
                              <Icon
                                className="flex h-auto w-6 items-center"
                                icon={icon}
                                sx={{
                                  svg: {
                                    display: "flex",
                                    alignItems: "center",
                                    width: "15px",
                                    height: "auto",
                                  },
                                }}
                              />
                            </InputAdornment>
                          )}
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "inherit",
                      fontWeight: "inherit",
                      fontSize: theme.typography.p2.fontSize,
                      px: variant !== "standard" ? "10px" : undefined,
                      py: size === "medium" ? "12.07px!important" : "7.32px!important",
                      gap: "4px",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderWidth: "2px",
                      borderTopWidth: "3.5px !important",
                      borderColor: disabled
                        ? `var(--palette-text-disabled)!important`
                        : `var(--palette-${color}-main)`,
                    },
                    "& input": {
                      py: "0 !important",
                      px: variant !== "standard" ? "5px" : undefined,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      "&::placeholder": {
                        opacity: 1,
                        color: "var(--palette-primary-main)",
                      },
                    },
                    "& .Mui-focused .MuiInputAdornment-root svg": {
                      color: `var(--palette-${color}-main)`,
                    },
                    "& .MuiAutocomplete-tag": {
                      my: 0,
                      mt: "1px",
                      color: "var(--palette-primary-main)",
                    },
                  }}
                />
              )}
            />
            {chipsPlacement === "outside" && !!selectedOptions.length && (
              <aside className="line-clamp-2 flex flex-wrap gap-1.5">
                {selectedOptions.map(({ key, value: label }) => (
                  <Chip
                    key={key}
                    size="small"
                    color="primary"
                    variant={chipVariant}
                    label={label}
                    onDelete={
                      disableClearable
                        ? undefined
                        : () => {
                            onChange(
                              multiple
                                ? selectedOptions.filter((v) => v.key !== key).map((v) => v.key)
                                : "",
                            );
                          }
                    }
                    sx={{
                      height: "fit-content",
                      py: "3px",

                      "&.MuiChip-root": {
                        color: "var(--palette-text-secondary) !important",
                        backgroundColor: "var(--palette-primary-dark)",
                      },

                      "& .MuiTypography-root": {
                        lineHeight: "1.5rem",
                        whiteSpace: "normal",
                      },
                    }}
                  />
                ))}
              </aside>
            )}
          </Box>
        );
      }}
    />
  );
};

export default AutoComplete;
