import { NumberInputSlots, numberInputClasses } from "@mui/base/Unstable_NumberInput";
import { prepareForSlot } from "@mui/base/utils";
import { styled } from "@mui/system";
import { INumberField } from "models/form";
import { ForwardedRef, forwardRef } from "react";

const InputRoot = styled("div")(
  ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: center;
  `,
);

const InputElement = styled("input", {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "fieldSize",
})<{ fieldSize: INumberField["size"] }>(
  ({ theme, color, fieldSize }) => `
  border: 2px solid var(--palette-${color}-main);
  border-radius: var(--shape-borderRadius);
  margin: 0 8px;
  padding: ${fieldSize === "small" ? "5px 12px" : "10px 12px"};
  outline: 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.4375em;
  font-feature-settings: "salt";
  width: 100%;

  &.${numberInputClasses.focused} {
    border-color: var(--palette-${color}-main);
  }

   &.${numberInputClasses.disabled} {
    border-color: var(--palette-text-disabled);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const Button = styled("button")(
  ({ theme }) => `
    border: 1px solid var(--palette-FilledInput-hoverBg);
    border-radius: 9999px;
    background: var(--palette-FilledInput-bg);
    color: var(--palette-text-primary);
    width: 32px;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;

    &:hover {
      cursor: pointer;
      background: var(--palette-FilledInput-hoverBg);
    }

    &:focus-visible {
      outline: 0;
    }

    &.increment {
      order: 1;
    }
  `,
);

const slots = ({ size }: { size: INumberField["size"] }): NumberInputSlots => ({
  root: prepareForSlot(InputRoot),
  input: prepareForSlot(
    // eslint-disable-next-line react/display-name
    forwardRef((props, ref: ForwardedRef<HTMLInputElement>) => (
      <InputElement ref={ref} {...props} fieldSize={size} />
    )),
  ),
  incrementButton: prepareForSlot(Button),
  decrementButton: prepareForSlot(Button),
});

export { slots };
